// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming

@use '@angular/material' as mat;


// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$ishbek-primary: mat.define-palette(mat.$indigo-palette);
$ishbek-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$ishbek-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$ishbek-theme: mat.define-light-theme((color: (primary: $ishbek-primary,
      accent: $ishbek-accent,
      warn: $ishbek-warn,
    )));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-legacy-component-themes($ishbek-theme);

/* You can add global styles to this file, and also import other style files */

/* Provide sufficient contrast against white background */

// @tailwind base;
// @tailwind components;
// @tailwind utilities;


@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';



.swal2-styled.swal2-confirm {
  border: 0;
  border-radius: .25em;
  background: initial;
  background-color: #00568f !important;
  color: #fff;
  font-size: 1em;
}

input[type=number] {
  -moz-appearance: textfield;
}

html, body { height: 100%; }
body {
  margin: 0;
  font-family: poppins, "Helvetica Neue", sans-serif;

}

@layer utilities{
  @variants reponsive{
    .no-scrollbar::-webkit-scrollbar{
      display: none;
    }
    .no-scrollbar{
      -ms-overflow-style:none;
      scrollbar-width:none;
    }
  }
}

@layer base {
  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button,
  input[type='number'] {
    -webkit-appearance: none;
    margin: 0;
    -moz-appearance: textfield !important;
  }
}
